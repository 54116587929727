
body, html, ul, li, a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.navbar-container {
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%; 
  height: 100px;
}

.navbar-links {
  display: flex;
  gap: 1em;
}

.navbar-links li a {
  color: white;
  padding: 0.5em 1em;
  transition: background-color 0.3s;
}

.navbar-links li a:hover {
  background-color: #555;
  border-radius: 5px;
}