.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-intro {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5rem;
  margin: 20px;
  white-space: pre; 
  display: flex;
}

.App-intro::after {
  content: '|';
  display: inline-block;
  animation: blink 0.7s steps(1) infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}

.App-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body, #root {
  height: 100%;
}

/* Add padding to the bottom of all content */
#root > div {
  padding-bottom: 100px;
}