.Projects {
    text-align: center;
    background-color: #282c34;
  }
  
  .Projects-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .Projects-intro {
      font-family: Courier, Verdana, Geneva, Tahoma, sans-serif;
      font-size: 1.5rem;
      margin: 20px;
      white-space: pre; 
      display: flex;
  }

  .Projects-subtitle {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 15px;
    white-space: pre; 
    display: flex;
  }

  .Projects-usage {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    font-size: 18px;
    margin: 10px;
    white-space: pre; 
    display: flex;
  }
  
  .Projects-video-container {
    display: flex;
    justify-content: center;
    padding: 0 15%;
    width: 100%;
    box-sizing: border-box;
  }

  .Projects-video video {
    width: 100%;
    height: 450px;
  }

  .Projects-video iframe {
    width: 100%;
    height: 450px;
  }

  .Projects-description-container {
    display: flex;
    justify-content: center;
    padding: 0 15%;
    box-sizing: border-box;
  }
  
  .Projects-description {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    margin: 10px;
    max-width: 100%;
  }

  .Projects-intro::after {
    content: '|';
    display: inline-block;
    animation: blink 0.7s steps(1) infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }