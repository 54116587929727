.About {
    text-align: center;
    padding-right: 40px;
    padding-left: 40px;
    background-color: #282c34;
  }

  .About-spacer {
    padding-top: 40px;
  }
  
  .About-header {
    padding-top: 100px;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .About-photo {
    width: 250px;
    align-items: center;
  }

  .About-intro {
    padding-top: 20px;
    margin-left: 20px;
    font-size: 20px;
  }

  .About-title {
    font-family: Courier, Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.5rem;
    margin: 20px;
    white-space: pre; 
    display: flex;
  }

  .About-intro li {
    margin-bottom: 10px; 
    text-align: left; 
}

  .About-intro-container {
    display: flex;
    align-items: center;
  }

  .About-intro-des {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 40px;
  }

  .About-story-container {
    align-items: center;
  }

  .About-story-subtile {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 30px;
    margin: 20px;
    margin-bottom: 20px;
    white-space: pre; 
    display: flex;
    justify-content: center;
  }

  .About-awards-container {
      align-items: center;
      width: 425px;
  }

  .About-slideshow-container {
    position: relative;
    max-width: 425px;
    margin: auto;
  }
  
  .mySlides {
    display: none;
  }
  
  .mySlides.active {
    display: block;
  }
  
  img {
    vertical-align: middle;
  }
  
.About-slide-title {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin: 15px;
  margin-bottom: 10px;
  white-space: pre; 
  display: flex;
  justify-content: center;
}

.About-slide-subtitle {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: italic;
  font-size: 18px;
  margin-bottom: 10px;
  white-space: pre; 
  display: flex;
  justify-content: center;
}

.About-slide-subheader {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #f2f2f2;
  font-size: 15px;
  padding-top: 20px;
  position: absolute;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
}

.About-slide-caption {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #f2f2f2;
  font-size: 15px;
  padding-top: 40px;
  position: absolute;
  width: 100%;
  text-align: left;
  }

.About-slide-caption-item {
  margin-bottom: 5px; /* Adds space between caption items */
}

  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    transform: translateY(-50%);
  }
  
  .next {
    right: -30px;
    border-radius: 3px 0 0 3px;
  }

  .prev {
    left: -30px;
    border-radius: 3px 0 0 3px;
  }
  
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
    border-radius: 0 3px 3px 0;

  }

  .About-subtitle {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 30px;
    margin: 20px;
    margin-bottom: 20px;
    white-space: pre; 
    display: flex;
    justify-content: center;
  }
  
  
  .About-title::after {
    content: '|';
    display: inline-block;
    animation: blink 0.7s steps(1) infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }