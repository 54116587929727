.Home {
    text-align: center;
    background-color: #282c34;
  }
  
  .Home-logo {
    height: 35vmin;
    pointer-events: none;
  }
  
  .Home-header {
    background-color: #282c34;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Home-link {
    color: #61dafb;
  }
  
  .Home-intro {
    font-family: Courier, Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.5rem;
    margin: 20px;
    white-space: pre; 
    display: flex;
  }
  
  .Home-intro::after {
    content: '|';
    display: inline-block;
    animation: blink 0.7s steps(1) infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }